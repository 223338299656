<template>
  <div>
    <!-- Modal Trigger -->
    <a class="btn wide-btn modal-trigger" :href="'#' + modalId">Skip signature</a>

    <!-- Modal Structure -->
    <div :id="modalId" class="modal mt-50">
      <div class="modal-content">
        <section class="nonEsign-form mt-10"> 
          <center>
            <h3 class="bold-font">Skip E-signature</h3>
            <span class='red-text bold-text'><i class="fas fa-exclamation-triangle red-text"></i> WARNING!</span><br> 
            I understand the higher risk of no signature transaction
            <br>
          </center>
        </section>
      </div>
      <div class="center-align">
        <button class="btn" @click="submit" :disabled="disabled">
          <span v-if="disabled">
            <i class="fas fa-spinner fa-spin grey-text"></i> 
            Processing ...
          </span>
          <span v-else>Skip signature</span>
        </button>
        <br><br>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    props: ["transactionId"],
    data() {
      return {
        disabled: false
      }
    },
    methods: {
      submit() {
        this.$validator.validateAll().then((result) => {
          if (result) {
            this.disabled = true
            this.$http.post(this.apiPath).then(response => {
              window.location.href = "/"
            }, error => {
              this.disabled = false
              this.errorHandler(error)
            })
          } else {
            this.notifyError("Please complete all required fields!")
          }
        })
      }
    },
    computed: {
      apiPath() {
        return  "/api/v1/app/merchant/manual_charges/" + this.transactionId + "/override"
      },
      modalId() {
        return "override_" + this.transactionId
      }
    }
  }
</script>
