<template>
  <div>
    <div v-if="$apollo.loading" class="center">
      <i class="fas fa-spinner fa-spin"></i> Updating balance...
    </div>
    <div v-else>
      <i class="fas fa-credit-card"></i> 
      Card Balance Available: {{balances.available | currency}} | Available soon: {{balances.pending | currency}} | 
      <a href @click.prevent="refetchBalances"><i class="fas fa-sync"></i></a> | <slot v-if="withdrawal"></slot>
    </div>
  </div>
</template>
<script>
  import gql from 'graphql-tag'

  export default {
    data() {
      return {
        balances: {
          available: 0,
          pending: 0
        }
      }
    },
    apollo: {
      balances: {
        query: gql`
          query GetMerchantData {
            merchant {
              merchantUser {
                balances {
                  available
                  pending
                }
              }
            }
          }
        `,
        update: data => data.merchant.merchantUser.balances,
        error(e) {
          this.gqlErrorHandler(e);
        }
      }
    },
    methods: {
      async refetchBalances() {
        try {
          await this.$apollo.queries.balances.refetch();
        } catch (error) {
          console.log("Error refetching balances");
        }
      }
    },
    computed: {
      withdrawal() {
        return this.balances.available > 100;
      }
    }
  }
</script>
