export default {
  payment: "Pago",
  to: "al",
  changeLanguage: "Change language",
  checkByPhoto: "Cheque por foto",
  replace: "Reemplace",
  pleaseConfirmCheckAmount: "Confirme que el monto del cheque sea igual a {amount} y envíelo",
  confirmCheckAmountAndSubmit: "Confirmar {amount} y enviar",
  submittedSuccessfully: "Enviado con éxito",
  // Pay by card
  payWithCard: "Paga con tarjeta",
  payWithAch: "Transferencia Bancaria",
  fillInCardDetails: "Rellene los datos de la tarjeta",
  pleaseSignHere: "Firme aquí por favor",
  pleaseSignHereAutoSubmit: "Firme aquí (se enviará el formulario)",
  noTip: "Sin propina",
  agreePayAboveAmount: "Estoy de acuerdo en pagar la cantidad anterior",
  agreePayAboveAmountFirst: "Estoy de acuerdo con",
  agreePayAboveAmountUrl: "política de la compañía",
  agreePayAboveAmountLast: "y a abonar el importe anterior, que no es reembolsable",
  grossAmount: "Cantidad bruta",
  nonCashAdj: "Ajuste no en efectivo",
  pay: "Pagar",
  processing: "Procesando...",
  loading: "Cargando...",
  couldNotProcess: "No se ha podido procesar el pago de este pedido",
  noPaymentMethod: "Póngase en contacto con el comerciante. No hay métodos de pago activos disponibles",
  buyNowPayLaterButton: "Compra y paga luego (Klarna)",
  withTipAmount: "Importe total con propinas",
  firstName: "Nombre",
  lastName: "Apellido",
  routing: "Número de ruta",
  accountNumber: "Número de cuenta"
}
